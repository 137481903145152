import React, { ChangeEvent } from 'react';
import DatePicker from 'react-datepicker';
import { downloadExcel } from 'react-export-table-to-excel';
import 'react-datepicker/dist/react-datepicker.css';

import './RecyclingProcessComponent.css';
import { RecyclingProcessComponentProps, RecyclingProcessComponentState } from './contracts';
import { RecyclingProcess } from '../../../contracts';
import { Paginator } from '../../../components/Paginator';
import { StartDialogProps } from '../../../components/DialogRenderer/StartDialogRenderer/contracts';

export class RecyclingProcessComponent extends React.Component<RecyclingProcessComponentProps & StartDialogProps, RecyclingProcessComponentState> {

  private minDate = '1970-01-01T00:00';
  private maxDate: string = this._getMaxDate();

  private firstDayOfMonthAsDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0);
  private lastDayOfMonthAsDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59);

  constructor(props: RecyclingProcessComponentProps & StartDialogProps) {
    super(props);

    this.state = {
      recyclingProcesses: null,
      position: 0,
      take: 10,
      pagesPerView: 5,
      processCount: 0,
      startDate: this.firstDayOfMonthAsDate.toISOString(),
      endDate: this.lastDayOfMonthAsDate.toISOString(),
      textSearch: '',
      hideInactiveProcesses: true,
    };

    this._handleStartDateChange = this._handleStartDateChange.bind(this);
    this._handleEndDateChange = this._handleEndDateChange.bind(this);
    this._handleTextSearchChange = this._handleTextSearchChange.bind(this);
    this._handleTextSearchKeyDown = this._handleTextSearchKeyDown.bind(this);
    this._handleDownloadExcel = this._handleDownloadExcel.bind(this);
    this._handleHideInactiveProcessesChange = this._handleHideInactiveProcessesChange.bind(this);
    this._resetFilter = this._resetFilter.bind(this);
    this._handlePageClick = this._handlePageClick.bind(this);
    this._handleBackwardClick = this._handleBackwardClick.bind(this);
    this._handleForwardClick = this._handleForwardClick.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    const count = await this.props.recyclingProcessApolloClient.getRecyclingProcessCount(this.state.startDate, this.state.endDate, this.state.hideInactiveProcesses, this.state.textSearch);

    this.setState({
      startDate: this.firstDayOfMonthAsDate.toISOString(),
      endDate: this.lastDayOfMonthAsDate.toISOString(),
      processCount: count,
    });
    await this._getProcessesByFilter();

  }

  public render(): JSX.Element {
    if (this.state.recyclingProcesses !== null) {
      const portalUrl = this.props.config.CustomerSupportPortalUrl;

      return (
        <div className='recycling-process-container'>
          <div className='recycling-process-container__datepicker flex justify-start items-center mb-4'>
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                <div className='datepicker__startDate flex flex-col'>
                  <label className='font-bold text-center'>Start-Date</label>
                  <DatePicker
                    className='datepicker text-center'
                    selected={new Date(this.state.startDate)}
                    showYearDropdown={true}
                    yearDropdownItemNumber={45}
                    scrollableYearDropdown
                    maxDate={new Date(this.maxDate)}
                    onChange={(date: Date) => this.setState({ startDate: date.toISOString() })}
                    todayButton={'Today'}
                    showTimeSelect
                    timeFormat='HH:mm'
                    timeIntervals={15}
                    timeCaption='time'
                    dateFormat='dd.MM.yyyy H:mm'
                  />
                </div>
                <div className='datepicker__endDate flex flex-col'>
                  <label className='font-bold text-center'>End-Date</label>
                  <DatePicker
                    className='datepicker text-center'
                    selected={new Date(this.state.endDate)}
                    showYearDropdown={true}
                    yearDropdownItemNumber={45}
                    scrollableYearDropdown
                    minDate={new Date(this.minDate)}
                    maxDate={new Date(this.maxDate)}
                    onChange={(date: Date) => this.setState({ endDate: date.toISOString() })}
                    todayButton={'Today'}
                    showTimeSelect
                    timeFormat='HH:mm'
                    timeIntervals={15}
                    timeCaption='time'
                    dateFormat='dd.MM.yyyy H:mm'
                  />
                </div>
              </div>
              <div className='flex justify-between'>
                <input id='hideInactiveProcessesCheckbox' checked={this.state.hideInactiveProcesses} type='checkbox' onChange={this._handleHideInactiveProcessesChange} name='checkbox' className='w-4 h-4 mt-4' />
                <label className='flex-1 ml-4 text-sm text-left mt-4 text-gray-900 break-words'>
                  <>Hide cancelled and completed processes</>
                </label>
              </div>
              <div className='flex justify-between mt-4'>
                <button className='bg-blue-500 disabled:bg-gray-700 enabled:hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 enabled:border-blue-700 enabled:hover:border-blue-500 rounded-xl'
                  onClick={() => {
                    this._getProcessesByFilter();
                  }}
                >Filter Processes by Time
                </button>
                <button className='bg-gray-300 disabled:bg-gray-700 enabled:hover:bg-gray-100 text-black font-bold py-2 px-4 border-b-4 enabled:border-gray-400 enabled:hover:border-gray-200 rounded-xl'
                  onClick={async () => await this._resetFilter()}
                >Reset Filter
                </button>
              </div>
            </div>

            <div className='flex flex-row ml-auto self-end'>
              <div className='flex flex-col gap-2 mr-4'>
                <button className='bg-blue-500 disabled:bg-gray-700 disabled:border-none enabled:hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 enabled:border-blue-700 enabled:hover:border-blue-500 rounded-xl'
                  onClick={() => this.props.startProcess('ProductMappingAdjustment', {})}
                  disabled={false}
                >Product Mapping
                </button>
                <button className='bg-blue-500 disabled:bg-gray-700 disabled:border-none enabled:hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 enabled:border-blue-700 enabled:hover:border-blue-500 rounded-xl'
                  onClick={async () => console.log(await this._getProductsAsExcel())}
                  disabled={false}
                >Download Current Mapping
                </button>
              </div>
              <button className='bg-blue-500 disabled:bg-gray-700 enabled:hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 enabled:border-blue-700 enabled:hover:border-blue-500 rounded-xl'
                onClick={this._handleDownloadExcel}
              >Export list to Excel
              </button>

            </div>

            <div className='flex flex-col ml-auto self-end'>
              <label className='mt-3 mb-2 text-sm font-bold text-gray-900'> <>Filter by text search</> </label>
              <div className='flex flex-row'>
                <input className='w-full p-2 text-gray-900 bg-gray-50 rounded-xl border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500'
                  type='text'
                  onChange={this._handleTextSearchChange}
                  onKeyDown={this._handleTextSearchKeyDown}
                  value={this.state.textSearch}
                />
              </div>
              <div className='flex justify-between mt-4'>
                <button className='bg-blue-500 disabled:bg-gray-700 enabled:hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 enabled:border-blue-700 enabled:hover:border-blue-500 rounded-xl'
                  onClick={() => {
                    this._getProcessesByFilter();
                  }}
                >Filter Processes by Text Search
                </button>

              </div>
            </div>

          </div>

          <table>
            <thead>
              <tr>
                <th>Process ID</th>
                <th>Process Start</th>
                <th>Product</th>
                <th>Manufacturer</th>
                <th>Country</th>
                <th>Tracking-ID</th>
                <th>Date Of Cancellation</th>
                <th>Date of Arrival</th>
                <th>Arrived Product</th>
                <th>Serial Number</th>
                <th>Actions</th>
              </tr>
            </thead>
            {this.state.recyclingProcesses.map((recyclingProcess) => {
              let arrivedProductCategoryName = recyclingProcess.arrivedProductCategory?.name ?? '';
              if (recyclingProcess.arrivedProductCategory?.isOther === true) {
                arrivedProductCategoryName = `${recyclingProcess.arrivedProductCategory?.name} (${recyclingProcess.arrivedProductCategoryOther})`;
              }

              let availableActionCaption = '';
              if (recyclingProcess.trackingId == undefined || isNaN(recyclingProcess.trackingId.length)) {
                availableActionCaption = 'Upload Label';
              } else if (recyclingProcess.trackingId !== undefined && (recyclingProcess.dateOfArrival == undefined || isNaN(recyclingProcess.dateOfArrival.length))) {
                availableActionCaption = 'Process Goods In';
              }

              // eslint-disable-next-line eqeqeq
              if (recyclingProcess.dateOfCancellation?.length || recyclingProcess.dateOfArrival?.length) {
                return (
                  <tbody key={recyclingProcess.id}>
                    <tr>
                      <td>{recyclingProcess.correlationId}</td>
                      <td className='text-right'>{recyclingProcess.processStart}</td>
                      <td>{recyclingProcess.chosenProductCategory.name}</td>
                      <td>{recyclingProcess.chosenManufacturer}</td>
                      <td>{recyclingProcess.country.name}</td>
                      <td>{recyclingProcess.trackingId}</td>
                      <td className='text-right'>{recyclingProcess.dateOfCancellation}</td>
                      <td className='text-right'>{recyclingProcess.dateOfArrival}</td>
                      <td>{arrivedProductCategoryName}</td>
                      <td>{recyclingProcess.serialNumber}</td>
                      <td><></></td>
                    </tr>
                  </tbody>
                );
              }

              return (
                <tbody key={recyclingProcess.id}>
                  <tr>
                    <td><a href={`${portalUrl}/correlation/${recyclingProcess.correlationId}`}>{recyclingProcess.correlationId}</a></td>
                    <td className='text-right'>{recyclingProcess.processStart}</td>
                    <td>{recyclingProcess.chosenProductCategory.name}</td>
                    <td>{recyclingProcess.chosenManufacturer}</td>
                    <td>{recyclingProcess.country.name}</td>
                    <td>{recyclingProcess.trackingId}</td>
                    <td className='text-right'>{recyclingProcess.dateOfCancellation}</td>
                    <td className='text-right'>{recyclingProcess.dateOfArrival}</td>
                    <td>{arrivedProductCategoryName}</td>
                    <td>{recyclingProcess.serialNumber}</td>
                    <td><a href={`${portalUrl}/correlation/${recyclingProcess.correlationId}`}>{availableActionCaption}</a></td>
                  </tr>
                </tbody>
              );
            })
            }

          </table>
          <Paginator
            entries={this.state.processCount}
            position={this.state.position}
            entriesPerPage={this.state.take}
            pagesPerView={this.state.pagesPerView}
            onClick={this._handlePageClick}
            onBackwardClick={this._handleBackwardClick}
            onForwardClick={this._handleForwardClick}
          />
        </div>
      );
    } else {
      return <></>;
    }
  }

  private async _handlePageClick(newPosition: number): Promise<void> {
    this.setState({
      position: newPosition,
    }, async () => await this._getProcessesByFilter());
  }

  private async _handleForwardClick(newPosition: number): Promise<void> {
    this.setState({
      position: newPosition,
    }, async () => {
      await this._getProcessesByFilter();
    });
  }

  private async _handleBackwardClick(newPosition: number): Promise<void> {
    this.setState({
      position: newPosition,
    }, async () => await this._getProcessesByFilter());
  }

  private _prepareRyceclingProcessHeaderForDownload(): string[] {
    return ['Process ID', 'Process Start', 'Process Start (CW)', 'Chosen Product-Category', 'Chosen Manufacturer', 'Country', 'Tracking ID', 'Date of Cancellation', 'Date of Cancellation (CW)', 'Date of Arrival', 'Date of Arrival (CW)', 'Arrived Product', 'Serial Number'];
  }

  private _prepareRyceclingProcessDataForDownload(recyclingProcesses: RecyclingProcess[]): { [key: string]: string | number | boolean; }[] {
    const result: { [key: string]: string | number | boolean; }[] = [];

    recyclingProcesses.forEach((recyclingProcess) => {
      let arrivedProductCategoryName = recyclingProcess.arrivedProductCategory?.name ?? '';
      if (recyclingProcess.arrivedProductCategory?.isOther === true) {
        arrivedProductCategoryName = `${recyclingProcess.arrivedProductCategory?.name} (${recyclingProcess.arrivedProductCategoryOther})`;
      }

      const processStartCalendarWeek = this._getCalendarWeekFromDate(new Date(recyclingProcess.processStart));
      const dateOfCancellationCalendarWeek = recyclingProcess.dateOfCancellation ? this._getCalendarWeekFromDate(new Date(recyclingProcess.dateOfCancellation)) : '';
      const dateOfArrivalCalendarWeek = recyclingProcess.dateOfArrival ? this._getCalendarWeekFromDate(new Date(recyclingProcess.dateOfArrival)) : '';

      result.push({
        correlationId: recyclingProcess.correlationId,
        processStart: new Date(recyclingProcess.processStart).toLocaleDateString(),
        processStartCalendarWeek: processStartCalendarWeek,
        chosenProductCategoryName: recyclingProcess.chosenProductCategory.name,
        chosenManufacturer: recyclingProcess.chosenManufacturer,
        countryName: recyclingProcess.country.name,
        trackingId: recyclingProcess.trackingId ? recyclingProcess.trackingId : '',
        dateOfCancellation: recyclingProcess.dateOfCancellation ? new Date(recyclingProcess.dateOfCancellation).toLocaleDateString() : '',
        dateOfCancellationCalendarWeek: dateOfCancellationCalendarWeek,
        dateOfArrival: recyclingProcess.dateOfArrival ? new Date(recyclingProcess.dateOfArrival).toLocaleDateString() : '',
        dateOfArrivalCalendarWeek: dateOfArrivalCalendarWeek,
        arrivedProductCategoryName: arrivedProductCategoryName,
        serialNumber: recyclingProcess.serialNumber ? recyclingProcess.serialNumber : '',
      });
    });

    return result;
  }

  private async _handleDownloadExcel(): Promise<void> {
    const header = this._prepareRyceclingProcessHeaderForDownload();
    const count = await this.props.recyclingProcessApolloClient.getRecyclingProcessCount(this.state.startDate, this.state.endDate, this.state.hideInactiveProcesses, this.state.textSearch);
    const filteredProcesses = await this.props.recyclingProcessApolloClient.getRecyclingProcessesByFilter(this.state.startDate, this.state.endDate, this.state.hideInactiveProcesses, this.state.textSearch, 0, count ?? 0);

    if (filteredProcesses.length > 0 && filteredProcesses !== null) {
      const recyclingProcessesForReport = this._prepareRyceclingProcessDataForDownload(filteredProcesses);

      downloadExcel({
        fileName: 'ExcelExport',
        sheet: 'ExcelExport',
        tablePayload: {
          header,
          body: recyclingProcessesForReport,
        },
      });
    }
  }

  private async _getProductsAsExcel(): Promise<void> {
    const products = await this.props.productApolloClient.getAllProducts();
    const header = ['ID', 'LOB', 'Family', 'Model', 'ProductCategory'];
    const body = products.map(product => [
      product.id,
      product.lob ?? '',
      product.family ?? '',
      product.model ?? '',
      product.productCategory.name ?? '',
    ]);

    downloadExcel({
      fileName: 'products',
      sheet: 'products',
      tablePayload: {
        header,
        body: body,
      },
    });
  }

  private _getMaxDate(): string {
    const today = new Date().toISOString().split('T');
    const time = today[1].split(':');

    return `${today[0]}T${time[0]}:${time[1]}`;
  }

  private _handleStartDateChange(e: ChangeEvent): void {
    const value = (e.target as HTMLInputElement).value;
    this.setState({
      startDate: value,
    });
  }

  private _handleEndDateChange(e: ChangeEvent): void {
    const value = (e.target as HTMLInputElement).value;
    this.setState({
      endDate: value,
    });
  }

  private async _handleHideInactiveProcessesChange(e: ChangeEvent): Promise<void> {
    const hideInactiveProcessesChecked = (e.target as HTMLInputElement).checked;

    this.setState({
      hideInactiveProcesses: hideInactiveProcessesChecked,
      position: 0,
    }, async () => await this._getProcessesByFilter());

  }

  private _handleTextSearchChange(e: ChangeEvent): void {
    const newTextSearchInput = (e.target as HTMLInputElement).value;

    this.setState({
      textSearch: newTextSearchInput,
    });
  }

  private async _handleTextSearchKeyDown(e: React.KeyboardEvent<HTMLInputElement>): Promise<void> {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      this.setState({
        position: 0,
      }, () => this._getProcessesByFilter());
    }
  }

  private async _resetFilter(): Promise<void> {
    this.setState({
      textSearch: '',
      position: 0,
      startDate: this.firstDayOfMonthAsDate.toISOString(),
      endDate: this.lastDayOfMonthAsDate.toISOString(),
      hideInactiveProcesses: true,
    }, async () => await this._getProcessesByFilter());
  }

  private async _getProcessesByFilter(): Promise<void> {
    const count = await this.props.recyclingProcessApolloClient.getRecyclingProcessCount(this.state.startDate, this.state.endDate, this.state.hideInactiveProcesses, this.state.textSearch);
    const filteredProcesses = await this.props.recyclingProcessApolloClient.getRecyclingProcessesByFilter(this.state.startDate, this.state.endDate, this.state.hideInactiveProcesses, this.state.textSearch, this.state.position, this.state.take);

    this.setState({
      processCount: count,
      recyclingProcesses: filteredProcesses,
    });
  }

  private _getCalendarWeekFromDate(inputDate: Date): number {
    const beginningOfYear = new Date(inputDate.getFullYear(), 0, 1);
    const calendarWeek = Math.ceil((((inputDate.getTime() - beginningOfYear.getTime()) / 86400000) + beginningOfYear.getDay() + 1) / 7);

    return calendarWeek;
  }
}
